import * as React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import "./NewsCard.scss"

const NewsCard = ({ thumbnail, title, link, description }) => {
  const findFirstPTagInArticle = description.substring(
    description.indexOf("<p>") + 1
  )
  const firstTextsInPTag = findFirstPTagInArticle.substring(2, 120)

  function extractFirstImageURL(html) {
    const regex = /<img[^>]+src="([^">]+)"/
    const match = html.match(regex)
    if (match && match[1]) {
      return match[1]
    }
    return ""
  }

  return (
    <div className="newsCard__container">
      <img src={extractFirstImageURL(description)} alt={title} />
      <OutboundLink
        href={link}
        className="newsCard__title"
        target="_blank"
        rel="noreferrer"
      >
        <h6>{title}</h6>
      </OutboundLink>
      <p
        dangerouslySetInnerHTML={{ __html: firstTextsInPTag }}
        className="newsCard__text"
      />
      <OutboundLink
        href={link}
        title={title}
        className="newsCard__link"
        target="_blank"
        rel="noreferrer"
      >
        Read more
      </OutboundLink>
    </div>
  )
}

export default NewsCard
