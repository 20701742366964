import React, { useState, useEffect, useCallback, useContext } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Title, Button } from "../ui"
import { myContext } from "../../provider"
import { displayLoadingMessage } from "../../utils"
import NewsCard from "./NewsCard"
import "./index.scss"

const News = () => {
  const context = useContext(myContext)

  const { posts, newsIsLoading, newsError } = context

  const [postsToShow, setPostsToShow] = useState([])
  const [postsPerPage, setPostsPerPage] = useState(6)

  const { fetchMediumPosts } = context

  const arrayForHoldingPosts = []

  const loopWithSlice = useCallback(
    (start, end) => {
      const slicedPosts = posts.slice(start, end)
      setPostsToShow(slicedPosts)
    },
    [posts]
  )

  useEffect(() => {
    loopWithSlice(0, postsPerPage)
  }, [posts, postsPerPage])

  useEffect(async () => {
    await fetchMediumPosts()
  }, [])

  const handleShowMorePosts = () =>
    setPostsPerPage(prevPostsPerPage => prevPostsPerPage + 3)

  return (
    <div>
      <Container>
        <Title header="News" color="BlackRussian" />
        {newsIsLoading && <p>{displayLoadingMessage}</p>}
        {newsError !== "" && <p style={error}>Error: {newsError}</p>}
        {postsToShow.length === 0 && !newsIsLoading ? (
          <p>No news article to show</p>
        ) : (
          <>
            <div className="news__section">
              {postsToShow.map(post => {
                const { guid, thumbnail, title, link, description } = post

                return (
                  <NewsCard
                    {...{
                      key: guid,
                      thumbnail,
                      title,
                      description,
                      link,
                    }}
                  />
                )
              })}
            </div>
            {postsToShow.length !== posts.length && (
              <div className="news__bottom">
                <Button
                  title="View more"
                  onClick={handleShowMorePosts}
                  customClass="news__btn"
                  type="button"
                  size="md"
                />
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  )
}

const error = {
  color: "var(--mainError)",
  fontWeight: 600,
}

export default News
